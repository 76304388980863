import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import { FOOTER_DISCLAIMER } from '../../constants/ui';
import { initApp } from '../../store/actions/init';
import { selectAccentColor, selectBaseColor, selectIsLoading } from '../../store/selectors/init';
import { selectIsInitialized } from '../../store/selectors/keys';
import { isAuthenticationRoute, isResultRoute, setAccentColor, setBaseColor } from '../../util/helpers';

import styles from './App.module.scss';

interface IProps {
  children: React.ReactNode;
}

function App({ children }: IProps) {
  const { search: initString, pathname } = useLocation();

  const dispatch = useDispatch();
  const isInitialized = useSelector(selectIsInitialized);
  const isLoading = useSelector(selectIsLoading);
  const baseColor = useSelector(selectBaseColor);
  const accentColor = useSelector(selectAccentColor);

  React.useEffect(() => {
    if (!isInitialized) {
      dispatch(initApp(initString));
    }
  }, [dispatch, initString, isInitialized]);

  React.useEffect(() => {
    if (baseColor) {
      setBaseColor(baseColor);
    }
    if (accentColor) {
      setAccentColor(accentColor);
    }
  }, [accentColor, baseColor]);

  return (
    <React.Fragment>
      {isLoading && <Loader isLoading />}
      <React.Fragment>
        {!isResultRoute(pathname) && !isAuthenticationRoute(pathname) && <Header />}
        {children}
        {!isResultRoute(pathname) && !isAuthenticationRoute(pathname) && (
          <React.Fragment>
            <Container>
              <Row>
                <Col xs={12} className={styles.safetyMessage}>
                  {FOOTER_DISCLAIMER}
                </Col>
              </Row>
            </Container>
            <Footer />
          </React.Fragment>
        )}
      </React.Fragment>
      <ToastContainer
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
      />
    </React.Fragment>
  );
}

App.whyDidYouRender = false;

export default App;
