import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SwiperSlide } from 'swiper/react';

import useWindowSize from '../../hooks/useWindowSize';
import { selectOrgData } from '../../store/selectors/init';
import { IInventoryItemSizeEnum } from '../../types/inventory';
import { ICampaignItem } from '../../types/pyg';
import { ModeSwitcherStatesEnum } from '../../types/ui';
import { isLightColor } from '../../util/ui';
import ModeSwitcher from '../inputs/ModeSwitcher/ModeSwitcher';
import InventoryItem from '../InventoryItem/InventoryItem';
import Slider from '../Slider/Slider';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styles from './CampaignItem.module.scss';

export interface ICampaignItemProps {
  title?: string;
  description?: string;
  items?: (ICampaignItem | undefined)[] | null;
  className?: string;
  isPicked?: boolean;
  shouldShowPickButton?: boolean;
  shouldShowPreorderWarning?: boolean;
  shouldShowCustomizeIcon?: boolean;
  isDimmed?: boolean;
  isDigital: boolean;
}

const CampaignItem: React.FC<ICampaignItemProps> = ({
  title,
  description,
  items,
  className,
  isPicked,
  shouldShowPickButton,
  shouldShowCustomizeIcon,
  shouldShowPreorderWarning,
  isDimmed = false,
  isDigital,
}) => {
  const orgData = useSelector(selectOrgData);
  const { mobile } = useWindowSize();

  const [mode, setMode] = React.useState(mobile ? ModeSwitcherStatesEnum.Single : ModeSwitcherStatesEnum.Expanded);

  const shouldShowModeSwitcher = React.useMemo(() => {
    return !mobile && items && items?.length > 1;
  }, [items, mobile]);

  const { button_color } = React.useMemo(() => orgData ?? { button_color: undefined }, [orgData]);

  const isButtonColorLight = React.useMemo(() => {
    return isLightColor(button_color);
  }, [button_color]);

  const isSingleMode = React.useMemo(() => {
    return mode === ModeSwitcherStatesEnum.Single;
  }, [mode]);

  const isSliderEnabled = React.useMemo(() => {
    const isMultipleItemsCount = items && items?.length > 1;
    return isSingleMode && isMultipleItemsCount;
  }, [isSingleMode, items?.length]);

  const renderItem = React.useCallback(
    (item: ICampaignItem | undefined) => {
      if (!item) {
        return null;
      }

      const { id, name, image_url, quantity, customization, isScalable, hint } = item;
      const { item_customization_allowed: isItemCustomizationAllowed } = customization || {};

      const isBigSize = isSingleMode || !shouldShowModeSwitcher || isSliderEnabled;

      return (
        <InventoryItem
          id={id}
          name={name}
          hint={hint}
          image_url={image_url}
          quantity={quantity}
          size={isBigSize ? IInventoryItemSizeEnum.Big : IInventoryItemSizeEnum.Small}
          isScalable={isScalable}
          key={id}
          isCustomizationAllowed={shouldShowCustomizeIcon && isItemCustomizationAllowed}
        />
      );
    },
    [mode, shouldShowModeSwitcher, isSliderEnabled, isSingleMode, shouldShowCustomizeIcon],
  );

  const isPreorder = React.useMemo(() => {
    return items?.some(
      (item) => item?.preorder_allowed || item?.sku_options?.some((option) => option?.preorder_allowed),
    );
  }, [items]);

  const renderItemsMarkup = React.useMemo(() => {
    if (isSliderEnabled) {
      return (
        <Slider className={styles.slider}>
          {items?.map((item) => (
            <SwiperSlide className={styles.slide} key={item?.id}>
              {renderItem(item)}
            </SwiperSlide>
          ))}
        </Slider>
      );
    }

    return items?.map(renderItem);
  }, [items, renderItem, isSliderEnabled]);

  return (
    <div className={cn(className, styles.container, { [styles.picked]: isPicked, [styles.dimmed]: isDimmed })}>
      <div className={styles.headerContainer}>
        <div className={styles.headingContainer}>
          {title && <p className={styles.title}>{title}</p>}
          <p className={styles.info}>{description || 'What’s inside:'}</p>
        </div>
        <div className={styles.controlsContainer}>
          {shouldShowModeSwitcher && <ModeSwitcher mode={mode} onSwitch={setMode} />}
        </div>
      </div>
      <div className={cn(styles.itemList, { [styles.multipleMode]: !isSingleMode })}>
        {renderItemsMarkup}
        {isDigital && <div className={styles.digitalLabel}>Delivered digitally</div>}
      </div>
      {shouldShowPickButton && (
        <div className={cn(styles.pickButton, { [styles.light]: isButtonColorLight })}>
          {isPicked ? 'Picked' : 'Pick'}
        </div>
      )}
      {shouldShowPreorderWarning && isPreorder && (
        <div className={styles.preorderLabel}>
          <b>Note:</b> One or more items in your gift are a Preorder. It will process and ship when those items are
          ready.
        </div>
      )}
    </div>
  );
};

export default CampaignItem;
