import { FormikHelpers } from 'formik';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContent } from 'react-toastify';

import CampaignItem from '../../components/CampaignItem/CampaignItem';
import { updateRequestAction } from '../../store/actions/form';
import { selectMappedPickedItems, selectPickedCampaign } from '../../store/selectors/derived';
import { selectFixedAddress, selectInitialAddress, selectIsDigital } from '../../store/selectors/init';
import { selectIsOneLinkFlow } from '../../store/selectors/keys';
import { IReceiverInfo } from '../../types/api';
import { IError } from '../../types/store';
import { isValidFixedAddress, mapDigitalReceiverInfo, mapPhysicalReceiverInfo } from '../../util/form';
import DigitalShippingForm from './DigitalShippingForm';
import PhysicalShippingForm from './PhysicalShippingForm';

import styles from './ShippingForm.module.scss';

const ShippingContainer = () => {
  const dispatch = useDispatch();

  const isDigital = useSelector(selectIsDigital);
  const isOneLink = useSelector(selectIsOneLinkFlow);
  const fixedAddress = useSelector(selectFixedAddress);
  const initialValues = useSelector(selectInitialAddress);
  const items = useSelector(selectMappedPickedItems);
  const selectedCampaign = useSelector(selectPickedCampaign);

  const isFixedAddressValid = React.useMemo(() => isValidFixedAddress(fixedAddress), [fixedAddress]);

  const handleSubmit: <T = IReceiverInfo>(values: Partial<T>, actions: FormikHelpers<Partial<T>>) => Promise<void> =
    React.useCallback(
      async (values, actions) => {
        await new Promise((resolve, reject) => {
          dispatch(updateRequestAction({ values, resolve, reject }));
        })
          .then(() => {
            actions.setSubmitting(false);
          })
          .catch((e: IError | unknown) => {
            actions.setSubmitting(false);
            const { message } = e as IError;

            if (message) {
              toast.error(<div className={styles.toast} dangerouslySetInnerHTML={{ __html: message }} />);
            } else {
              toast.error(e as ToastContent);
            }
          });
      },
      [dispatch],
    );

  return (
    <Container className={styles.container}>
      <Row className={styles.contentRow}>
        <Col md={12} lg={8} className={styles.formContainer}>
          <Row className="col-12 mb-4 mx-0 flex-center">
            {isDigital || isFixedAddressValid ? (
              <DigitalShippingForm
                onSubmit={handleSubmit}
                initialValues={mapDigitalReceiverInfo(initialValues)}
                address={isFixedAddressValid ? fixedAddress : undefined}
              />
            ) : (
              <PhysicalShippingForm
                onSubmit={handleSubmit}
                initialValues={mapPhysicalReceiverInfo(initialValues)}
                isOneLink={isOneLink}
              />
            )}
          </Row>
        </Col>
        {items.length ? (
          <Col md={12} lg={6} xl={4} className={styles.pickedCampaignContainer}>
            <CampaignItem
              shouldShowCustomizeIcon
              className={styles.pickedCampaign}
              items={items}
              shouldShowPreorderWarning
              isDigital={isDigital}
              title={selectedCampaign?.name}
            />
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

export default ShippingContainer;
