import { MouseEvent, useCallback } from 'react';
import * as React from 'react';
import { countryList } from '../../../constants/countries';

import { RECEIVER_COUNTRY } from '../../../constants/shipping';
import { getCountryNameByTwoDigits } from '../../../util/country';
import CountryList from '../../CountryList/CountryList';
import LookupSearch from '../../LookupSearch/LookupSearch';

interface IProps {
  className?: string;
  value?: string;
  onSelect: (value: string) => void;
  error?: string;
}

const CountrySelect = ({ value, onSelect, error }: IProps) => {
  const [country, setCountry] = React.useState('');

  const filteredCountries = React.useMemo(
    () => countryList.filter(({ name }) => name.toLocaleLowerCase().includes(country?.toLocaleLowerCase())),
    [country],
  );

  const setCountryNameByTwoDigit = React.useCallback((digitCode: string) => {
    const selectedCountry = getCountryNameByTwoDigits(digitCode);
    if (selectedCountry) {
      setCountry(selectedCountry.name);
    }
  }, []);

  const handleBlur = React.useCallback(() => {
    if (value) {
      setCountry(countryList.find((c) => c.two_digit_code === value)?.name ?? '');
    }
  }, [value]);

  const handleSelect = useCallback(
    (e: MouseEvent<HTMLButtonElement>, cb: (isActive: boolean) => void) => {
      onSelect(e.currentTarget.value);
      cb(false);
      setCountryNameByTwoDigit(e.currentTarget.value);
    },
    [onSelect, setCountryNameByTwoDigit],
  );

  React.useEffect(() => {
    const newCountryName = getCountryNameByTwoDigits(value ?? '');
    if (newCountryName && newCountryName.name !== country) {
      setCountry(newCountryName.name);
    }
  }, [value, country]);

  return (
    <LookupSearch
      name={RECEIVER_COUNTRY}
      label="Country"
      onFocus={(e, val) => setCountry(val)}
      onChange={(e, val) => setCountry(val)}
      onDelete={() => onSelect('')}
      required
      isLoading={false}
      onBlur={handleBlur}
      value={country}
      error={error}
      autoComplete={false}
      result={({ setActive }) => (
        <CountryList
          onSelect={(e) => {
            handleSelect(e, setActive);
          }}
          items={filteredCountries}
          name={RECEIVER_COUNTRY}
          selected={value}
        />
      )}
    />
  );
};

export default CountrySelect;
