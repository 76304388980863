import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { USA } from '../../constants/countries';
import {
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_EMAIL,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
} from '../../constants/shipping';
import { IPhysicalReceiverInfo } from '../../types/api';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';
import Input from '../Input/Input';
import CountrySelect from '../inputs/CountrySelect/CountrySelect';

import styles from './Forms.module.scss';

interface IProps {
  className?: string;
  form: FormikProps<Partial<IPhysicalReceiverInfo>>;
  isOneLink?: boolean;
  onSelect: (i: any) => void;
  onReset?: () => void;
}

const FormPhysical = ({ form, className, isOneLink, onSelect }: IProps) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value && Object.keys(form.errors).length) {
        form.setTouched({});
        form.setErrors({});
      }
      form.handleChange(e);
    },
    [form.handleChange, form.setTouched, form.errors],
  );

  const isUSA = React.useMemo(
    () => form.values[RECEIVER_COUNTRY] === USA.two_digit_code,
    [form.values[RECEIVER_COUNTRY]],
  );

  const handleCountrySelect = React.useCallback(
    (country: string) => {
      form.setTouched({});
      form.setErrors({});

      form.setFieldValue(RECEIVER_COUNTRY, country, true);
    },
    [form.setFieldValue, form.setTouched, form.setErrors],
  );

  return (
    <Form className={cn(className, styles.shippingForm)} onSubmit={form.handleSubmit} onReset={form.handleReset}>
      <div className={styles.formTitle}>Enter Delivery Address</div>
      <Row className={styles.row}>
        <Col className={styles.col}>
          <Input
            isRequired
            label="First Name"
            placeholder="First Name"
            type="text"
            name={RECEIVER_FIRST_NAME}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_FIRST_NAME] ? form.errors[RECEIVER_FIRST_NAME] : undefined}
            value={form.values[RECEIVER_FIRST_NAME] || ''}
          />
        </Col>

        <Col className={styles.col}>
          <Input
            isRequired
            label="Last Name"
            placeholder="Last Name"
            type="text"
            name={RECEIVER_LAST_NAME}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_LAST_NAME] ? form.errors[RECEIVER_LAST_NAME] : undefined}
            value={form.values[RECEIVER_LAST_NAME] || ''}
          />
        </Col>
      </Row>

      <Row className={styles.row}>
        <Col className={styles.col}>
          <Input
            isRequired
            label="Phone"
            placeholder="Phone"
            type="text"
            name={RECEIVER_PHONE}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_PHONE] ? form.errors[RECEIVER_PHONE] : undefined}
            value={form.values[RECEIVER_PHONE] || ''}
          />
        </Col>

        <Col className={styles.col}>
          <Input
            isRequired={isOneLink}
            label="Email"
            placeholder="Email"
            type="text"
            name={RECEIVER_EMAIL}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_EMAIL] ? form.errors[RECEIVER_EMAIL] : undefined}
            value={form.values[RECEIVER_EMAIL] || ''}
          />
        </Col>
      </Row>

      <Row className={styles.row}>
        <Col className={styles.col}>
          <Form.Group as={Col} controlId="receiver_company_name">
            <Input
              label="Company Name"
              placeholder="Company Name"
              type="text"
              name={RECEIVER_COMPANY_NAME}
              onChange={handleChange}
              onBlur={form.handleBlur}
              error={form.touched[RECEIVER_COMPANY_NAME] ? form.errors[RECEIVER_COMPANY_NAME] : undefined}
              value={form.values[RECEIVER_COMPANY_NAME] || ''}
            />
          </Form.Group>
        </Col>
        <Col className={styles.col}>
          <CountrySelect
            value={form.values[RECEIVER_COUNTRY]}
            onSelect={handleCountrySelect}
            error={form.touched[RECEIVER_COUNTRY] ? form.errors[RECEIVER_COUNTRY] : undefined}
          />
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col className={styles.col}>
          {isUSA ? (
            <AutoCompleteInput
              label="Address Line 1"
              isRequired
              name={RECEIVER_ADDRESS1}
              onChange={handleChange}
              onBlur={form.handleBlur}
              error={form.touched[RECEIVER_ADDRESS1] ? form.errors[RECEIVER_ADDRESS1] : undefined}
              value={form.values[RECEIVER_ADDRESS1]}
              onSelect={onSelect}
              autoComplete="nope!"
            />
          ) : (
            <Input
              isRequired
              label="Address Line 1"
              placeholder="Address Line 1"
              type="text"
              name={RECEIVER_ADDRESS1}
              onChange={handleChange}
              onBlur={form.handleBlur}
              error={form.touched[RECEIVER_ADDRESS1] ? form.errors[RECEIVER_ADDRESS1] : undefined}
              value={form.values[RECEIVER_ADDRESS1] || ''}
            />
          )}
        </Col>
      </Row>

      <Row className={styles.row}>
        <Col className={styles.col}>
          <Input
            label="Address Line 2"
            placeholder="Address Line 2"
            type="text"
            name={RECEIVER_ADDRESS2}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_ADDRESS2] ? form.errors[RECEIVER_ADDRESS2] : undefined}
            value={form.values[RECEIVER_ADDRESS2] || ''}
          />
        </Col>
        <Col className={styles.col}>
          <Input
            isRequired
            label="City"
            placeholder="City"
            type="text"
            name={RECEIVER_CITY}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_CITY] ? form.errors[RECEIVER_CITY] : undefined}
            value={form.values[RECEIVER_CITY] || ''}
          />
        </Col>
      </Row>

      <Row className={styles.row}>
        <Col className={styles.col}>
          <Input
            isRequired={isUSA}
            label="State / Region"
            placeholder="State / Region"
            type="text"
            name={RECEIVER_STATE}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_STATE] ? form.errors[RECEIVER_STATE] : undefined}
            value={form.values[RECEIVER_STATE] || ''}
          />
        </Col>

        <Col className={styles.col}>
          <Input
            isRequired
            label="ZIP Code"
            placeholder="ZIP Code"
            type="text"
            name={RECEIVER_ZIP}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_ZIP] ? form.errors[RECEIVER_ZIP] : undefined}
            value={form.values[RECEIVER_ZIP] || ''}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FormPhysical;
